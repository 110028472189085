import React from "react";
import { useTranslation } from "react-i18next";
import {
  FaFacebookF,
  FaInstagram,
  FaSquareFull,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import Contacts from "./Contacts";
import TeamCard from "./TeamCard";
import { partners } from "./partners";

const ContactUs = () => {
  const [t, i18n] = useTranslation("global");
  const logos = [
    "/mundo.png",
    "/school.png",
    "/asia-buissnes.png",
    "/umtech.png",
    "/ss-vision2.png",
    "Asset 1-8.png",
    "astouch logo.png",
  ];

  const partner2 = {
    name: "Dr.Babak Moradi",
    img: "/colluges/teamate.jpg",
    desc: [
      "Dr. Babak Moradi is a highly accomplished professional specializing in sustainable organizational performance, strategic management, and human resource development. He earned a Doctorate in Sustainable Organizational Performance from Birmingham City University (BCU) and also holds a Master of Science in Logistics and a degree in Industrial Engineering from BCU.",
      "Dr. Moradi's career includes significant roles in the UK, Iran, and globally. Early on, he worked at Birmingham Commercial Watch Assembly and Land Rover Car Group (1995-1997). He then excelled at SAPCO (1998-2002) and SAIPA, where he improved productivity as Productivity Manager and General Manager of Productivity Development (2002-2007). As Plant Manager at Aluminium Pars (2007-2009), he enhanced production processes. His consultancy role at Caspian Food Ltd (2009-2014) and his current position as Sales Area Manager at ELC since 2018 highlight his versatility.",
    ],
  };
  const partner3 = {
    name: "Behnam Najafi",
    img: "/colluges/behnam.png",
    desc: [
      " Ph.D. in Management from the University of Tehran Post-DBA from the University of Science andTechnology in Tehran DBA from the University of Tehran",
      "MBA from University of Tehran Bachelor's and Master's degrees in Petroleum Exploration",
    ],
  };
  const partner4 = {
    name: "Sara Rasoli ",
    img: "/colluges/sara.jpg",
    desc: [
      " As an experienced and dynamic Business Explorer, I thrive on uncovering new opportunities across diverse industries. With a natural flair for public speaking and leadership, my background in journalism and advertising campaign strategies equips me with the storytelling prowess and strategic insight needed to captivate audiences and drive impactful campaigns. My adept communication and negotiation skills enable me to forge strong relationships with clients and stakeholders, fostering collaboration and driving sustainable growth. Committed to excellence, I am poised to make a significant contribution to the success of any dynamic organization.",
      "Master of Strategic Marketing Industrial Management Institute, Iran Bachelor of Biochemistry Medical University Tehran, Tehran, Iran",
    ],
  };
  const partner5 = {
    name: "Dr. Makoto Kawada",
    img: "/colluges/kawada.png",
    desc: [
      " Production Systems, Management Accounting,  Marketing Theory, Small & Medium Enterprise Management ",
      "How to enhance the Possibility of Successful Introduction of TPS Overseas  ",
      "Strategic Management Accounting Why and How, Management Accounting, Aug.（Certificate Appreciation Award, 1993-1994）",
    ],
  };

  return (
    <div>
      <div className="lg:mt-[45px] mt-5">
        <img
          className="w-full lg:h-[60vh] object-cover  object-center"
          src="/slider/slider3.jpg"
          alt=""
        />
      </div>
      <div className=" mt-10   ">
        <div className="gap-5 flex flex-col lg:text-[17px] lg:px-[150px] px-4">
          <div className="flex flex-col gap-5 items-start">
            <h1 className="  border-b-2 border-blue-500 text-3xl pb-2 uppercase mx-auto">
              {t("aboutus")}
            </h1>
            <h5 className="font-bold mt-4 text-left">{t("contact-us-h")}</h5>
            <p className="">{t("contact-us-p")}</p>
            <p>{t("contact-us-p-1")}</p>
            <p>{t("contact-us-p-2")}</p>
            <p>{t("contact-us-p-3")}</p>
            <p className="mt-5">{t("contact-us-p-4")}</p>
            <p>{t("contact-us-p-5")}</p>
            <p>{t("contact-us-p-6")}</p>
          </div>
          {/* <div className=" lg:mt-10 mt-2">
            <span className="font-bold">Objective:</span>
            <ul className="ml-10 gap-6 flex flex-col mt-5 list-decimal list-inside">
              <li>{t("contact-us-li-1")}</li>
              <li> {t("contact-us-li-2")}</li>
              <li> {t("contact-us-li-3")}</li>
              <li> {t("contact-us-li-4")}</li>
            </ul>
          </div>
          <div className="lg:mt-10 mt-2">
            <span className="font-bold">Strategies:</span>
            <ul className="ml-10 gap-6 flex flex-col mt-5 list-decimal list-inside">
              <li>{t("contact-us-li-5")}</li>
              <li>{t("contact-us-li-6")}</li>
              <li>{t("contact-us-li-7")}</li>
              <li>{t("contact-us-li-8")}</li>
              <li>{t("contact-us-li-9")}</li>
            </ul>
          </div> */}
          <div>
            <p>{t("contact-us-p-7")}</p>
          </div>
        </div>
        <div className="lg:mx-[150px] mx-3 flex flex-col items-center mt-10 mb-5">
          <h1 className="border-b-2 border-blue-500 lg:text-3xl text-2xl pb-2 uppercase">
            {t("our-staff")}
          </h1>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-20 lg:my-10 my-3">
            <div className=" lg:order-2 order-1">
              <img
                className="w-full max-h-[420px] rounded-xl shadow-lg object-cover object-top "
                src={partner2.img}
                alt=""
              />
            </div>
            <div className="flex flex-col justify-center lg:order-1 order-2">
              <h1 className="text-xl font-bold my-2">{partner2.name}</h1>
              {partner2.desc.map((e) => (
                <span className="flex justify-start items-center py-1 gap-2 text-[14px] lg:text-[16px]">
                  <p className="my-2">{e}</p>
                </span>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-20 lg:my-10 my-3">
            <div className=" lg:order-1 order-1">
              <img
                className="w-full max-h-[420px] rounded-xl shadow-lg object-cover object-top "
                src={partner3.img}
                alt=""
              />
            </div>
            <div className="flex flex-col justify-center lg:order-2 order-2">
              <h1 className="text-xl font-bold my-2">{partner3.name}</h1>
              {partner3.desc.map((e) => (
                <span className="flex justify-start items-center py-1 gap-2 text-[14px] lg:text-[16px]">
                  <p className="my-2">{e}</p>
                </span>
              ))}
              <a
                href={"/1.pdf"}
                target="_blank"
                className="bg-red-500 text-white px-6 py-2 rounded-3xl w-fit mx-1 text-[16px] hover:bg-red-600 duration-100"
              >
                See Resume
              </a>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-20 lg:my-10 my-3">
            <div className=" lg:order-2 order-1">
              <img
                className="w-full max-h-[420px] rounded-xl shadow-lg object-cover object-top  "
                src={partner4.img}
                alt=""
              />
            </div>
            <div className="flex flex-col justify-center lg:order-1 order-2">
              <h1 className="text-xl font-bold my-2">{partner4.name}</h1>
              {partner4.desc.map((e) => (
                <span className="flex justify-start items-center py-1 gap-2 text-[14px] lg:text-[16px]">
                  <p className="my-2">{e}</p>
                </span>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-20 lg:my-10 my-3">
            <div className=" lg:order-1 order-1">
              <img
                className="w-full max-h-[420px] rounded-xl shadow-lg object-cover object-top "
                src={partner5.img}
                alt=""
              />
            </div>
            <div className="flex flex-col justify-center lg:order-2 order-2">
              <h1 className="text-xl font-bold my-2">{partner5.name}</h1>
              {partner5.desc.map((e) => (
                <span className="flex justify-start items-center py-1 gap-2 text-[14px] lg:text-[16px]">
                  <p className="my-2">{e}</p>
                </span>
              ))}
              <a
                href="/2.pptx"
                target={"_blank"}
                className="bg-red-500 text-white px-6 py-2 rounded-3xl w-fit mx-1 text-[16px] hover:bg-red-600 duration-100"
              >
                See Resume
              </a>
            </div>
          </div>
        </div>
        <div
          // style={{ backgroundImage: "url(/bg.jpg)" }}
          class=" py-10 lg:py-16 relative   mt-9"
        >
          <h2 className="text-2xl lg:text-4xl   border-b-2 border-blue-500 pb-2 mx-auto w-fit text-black  text-center lg:text-left z-10 relative">
            {t("valued-partner")}
          </h2>
          <div class="pt-12  lg:px-[150px]  lg:gap-y-0 lg:gap-12 p-2 z-10 relative">
            <Swiper
              breakpoints={{
                0: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                480: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                800: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
              }}
              autoplay={{
                delay: 2000,
              }}
              modules={[Autoplay]}
              pagination={{
                clickable: true,
              }}
              loop
              className=" "
            >
              {logos.map((logo) => (
                <SwiperSlide>
                  <div className="flex justify-center items-center w-full h-full">
                    <img className="w-[200px] " src={logo} alt="" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div>
          <img src="/map-earth.png" alt="" />
        </div>
        {/* <div className="flex flex-col items-center justify-center mt-8 lg:px-[120px] px-4">
          <h1 className="  border-b-2 border-blue-500 text-3xl pb-2">
            CONTACT US
          </h1>
          <div className="w-full mt-5 ">
            <div className="grid grid-cols-1  lg:grid-cols-2 lg:text-[16px] lg:mx-[150px]">
              <div className="flex flex-col gap-2 my-2">
                <div className="flex">
                  <span>{t("addres")} :</span>{" "}
                  <p className="flex">
                    22.st,Al Quez,Al Quez Industrial area 3{" "}
                    <p className="text-red-500">U.A.E</p>
                  </p>
                </div>
                <Link to={"tel:+971 542957751"} className="flex gap-7">
                  <span>Tell:</span> <p>+971 542957751</p>
                </Link>
                <Link to={"tel:+971 545207902"} className="flex gap-7">
                  <span>Tell:</span> <p>+971 545207902</p>
                </Link>
                <Link to={"tel:+971 588506469"} className="flex gap-7">
                  <span>Tell:</span> <p>+971 588506469</p>
                </Link>
              </div>
              <div className="flex flex-col gap-2 my-2">
                <div className="flex">
                  <span>{t("addres")}:</span>{" "}
                  <p className="flex">
                    NO.104,Swinford Rd,.Birmingham B29 5SX
                    <p className="text-red-500">England</p>
                  </p>
                </div>
                <Link to={"tel:+971 542957751"} className="flex gap-7">
                  <span>Tell:</span> <p>+44 7403043461</p>
                </Link>

                <Link to={"mail:info@thetivan.com"} className="flex gap-4 ">
                  <span>{t("email")}:</span> <p>info@thetivan.com</p>
                </Link>
              </div>
            </div>
            <div className="grid grid-cols-4  justify-items-center  items-start mt-8 lg:mx-[200px]">
              <Link className="bg-red-600 rounded-full   m-3 text-[30px] p-3 text-white ">
                <FaInstagram />
              </Link>
              <Link className="bg-red-600 rounded-full  m-3 text-[30px] p-3 text-white">
                <FaFacebookF />
              </Link>
              <Link className="bg-red-600 rounded-full  m-3 text-[30px] p-3 text-white">
                <FaTwitter />
              </Link>
              <Link className="bg-red-600 rounded-full  m-3 text-[30px] p-3 text-white">
                <FaYoutube />
              </Link>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ContactUs;
